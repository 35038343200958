import React, { useEffect, navigate } from "react";
import { useUiTranslationQuery } from "../hooks/useUiTranslationQuery";
import supportedLanguages from '../data/supportedLanguages';

const NotFoundPage = () => {
  const translation = useUiTranslationQuery();
  let savedLang = 'en'
  useEffect(() => {
    savedLang = localStorage.getItem('preferredLanguage');

    if (!savedLang) {
      const browserLang = (navigator.language || navigator.userLanguage).substring(0, 2);
      savedLang = supportedLanguages.includes(browserLang) ? browserLang : 'en';
      localStorage.setItem('preferredLanguage', savedLang);
    }
    //navigate(`/${savedLang}/`);
  }, []);


  return (
    <div className="p-4">
      <h1 className="font-bold">Page Not Found</h1>
      <p>The page you requested was not found.</p>
      <p className="mt-4">Please go back or <a href={`/${savedLang}/`} className="border p-1 rounded-full px-3 text-sky-950 bg-amber-100" >klick here</a></p>
    </div>
  );


}
export default NotFoundPage;