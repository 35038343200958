import { useStaticQuery, graphql } from "gatsby";
/**
 * Achtung! Diese .md Files liegen hier:
 * 
 * *****   src/data/ui/ui-de.md *****
 * 
 */

export const useUiTranslationQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { type: { eq: "ui" } } }) {
        nodes {
          frontmatter {
            language
            minutes
            rules
            _year
            years
            duration
            players
            info
            tutorial
            all_games
            search_game
            preview
            feedback
            feedback_zusatz
            copied_2_clipboard
            title_main
            meta_main
            title_game
            meta_game
            meta_game_2
            title_rules
            meta_rules
            meta_rules_2
            meta_rules_3
            choose_language
          }
          rawMarkdownBody
        }
      }
    }
  `);

  return data.allMarkdownRemark.nodes;
};
